import React, { useState, useEffect } from 'react';
import img1 from './projectImg/img1.jpeg'
import img2 from './projectImg/img2.jpeg'
import img3 from './projectImg/img3.jpeg'
import img4 from './projectImg/img4.jpeg'
import img5 from './projectImg/img5.jpeg'
import img6 from './projectImg/img6.jpeg'
import img7 from './projectImg/img7.jpeg'
import img8 from './projectImg/img8.jpeg'
import img9 from './projectImg/img9.jpeg'
import img10 from './projectImg/img10.jpeg'
import img11 from './projectImg/img11.jpeg'
import img12 from './projectImg/img12.jpeg'
import img13 from './projectImg/img13.jpeg'
import img14 from './projectImg/img14.jpeg'
import img15 from './projectImg/img15.jpeg'
import img16 from './projectImg/img16.jpeg'
import img17 from './projectImg/img17.jpeg'
import img18 from './projectImg/img18.jpeg'
import img19 from './projectImg/img19.jpeg'
import style from './project.module.css'
import { Link } from "react-router-dom";

export type ProjType = {
    src: string;
    text: string;
    link: string,
    header: string
};

export const projInfo: ProjType[] = [
    { src: img1, text: '', link: '/projects', header: 'Географическое общество' },
    { src: img2, text: '', link: '/projects', header: 'Храм Василия Блаженного' },
    { src: img3, text: '', link: '/projects', header: '"Елисеевкий" рынок' },
    { src: img4, text: '', link: '/projects', header: 'Российское военно-историческое общество'},
    { src: img5, text: '', link: '/projects', header: 'Ресторан «Салюмерия / Salumeria»'},
    { src: img6, text: '', link: '/projects', header: 'Дом Пашкова'},
    { src: img7, text: '', link: '/projects', header: 'Частный дом'},
    { src: img8, text: '', link: '/projects', header: 'Частный интерьер в центре Москвы'},
    { src: img9, text: '', link: '/projects', header: 'Большой театр'},
    { src: img10, text: '', link: '/projects', header: 'Школа Современной Пьесы'},
    { src: img11, text: '', link: '/projects', header: 'Российское Историческое Общество'},
    { src: img12, text: '', link: '/projects', header: 'Московский метрополитен, станция Котельники'},
    { src: img13, text: '', link: '/projects', header: 'Московский метрополитен, станция Выхино'},
    { src: img14, text: '', link: '/projects', header: 'Московский метрополитен, станция Кожуховская'},
    { src: img15, text: '', link: '/projects', header: 'Московский метрополитен, станция Кунцевская'},
    { src: img16, text: '', link: '/projects', header: 'Московский метрополитен, станция Косино'},
    { src: img17, text: '', link: '/projects', header: 'Московский метрополитен, станция Лухмановская'},
    { src: img18, text: '', link: '/projects', header: 'Московский метрополитен, станция Серпуховская'},
    { src: img19, text: '', link: '/projects', header: 'Московский метрополитен, электродепо Солнцево'}


];



const Project: React.FC<any> = ({projInfo}) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };
    return (
        <Link to={projInfo.link}
              className={style.designCard}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
        >
            <div className={style.imageContainer}>
                <img src={projInfo.src}/>
            </div>
            <div>
                <h3 className={`${style.text} ${isHovered ? style.hovered : ''}`}>
                    {projInfo.header}
                </h3>
            </div>
        </Link>
    );
};

export default Project;