import React, {useState} from 'react';
import style from './header.module.css';
import logo from './logo.png'
import phone_icon from './phone_icon.jpg'
import menu_icon from './menu.svg'
import cross from '../contact/cross_icon.png'
import {  Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const scrollToElement = () => {
    const element = document.getElementById('myElement');
    // @ts-ignore
    element.scrollIntoView({ behavior: "smooth" }); // Для плавной прокрутки
}


function Header () {
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdownDoor, setShowDropdownDoor] = useState(false);
    const [showDropdownWindow, setShowDropdownWindow] = useState(false);

    const handleMouseDoor = () => {
        showDropdownDoor ? setShowDropdownDoor(false) : setShowDropdownDoor(true);
    };
    const handleMouseWindow = () => {
        showDropdownWindow ? setShowDropdownWindow(false) : setShowDropdownWindow(true);
    };


    const handleMouseEnter = () => {
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        setShowDropdown(false);
    };
    return (
        <header className={style.header}>
            {menuOpen && (
                <section className={style.menuSection}>
                    <div className={style.menuHeader}>
                        <div className={style.logoContainer}>
                            <img  className={style.logo} src={logo} alt="Логотип" />
                        </div>
                        <button className={style.closeBtn} onClick={() => {setMenuOpen(false);}}>
                            <img src={cross}/>
                        </button>
                    </div>
                    <ul className={style.menuList}>
                        <li><Link to="/" className={style.link}>О компании</Link></li>
                        <li><Link to="/services" className={style.link}>Услуги</Link></li>
                        <li><Link to="/projects" className={style.link}>Проекты</Link></li>
                        <li><HashLink to="/#contact" smooth className={style.link}>Контакты</HashLink></li>
                        <li className={style.menuItem} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <div className={style.link}>Окна</div>
                            {showDropdown && (
                                <div className={style.dropdown}>
                                    <Link className={style.link} to="/restore/windowrestore">реставрация окон</Link>
                                    <Link className={style.link} to="/windowmanufactoring">изготовление окон</Link>
                                </div>
                            )}
                        </li>
                        <li><Link to="/production" className={style.link}>Производство</Link></li>
                    </ul>
                    <button onClick={scrollToElement} className={style.button}>Подать заявку</button>
                </section>
            )}
            <button className={style.menuBtn} onClick={toggleMenu}>
                <img className={style.menuIcon} src={menu_icon} alt="menue icon"/>
            </button>
            <div className={style.logoContainer}>
                <img  className={style.logo} src={logo} alt="Логотип" />
            </div>



            <nav className={style.menu}>
                <ul className={style.ulUl}>
                    <li><Link to="/" className={style.link}> о компании</Link></li>
                    <li><Link to="/services" className={style.link}>услуги</Link></li>
                    <li><Link to="/projects" className={style.link}>проекты</Link></li>
                    <li><HashLink to="/#contact" smooth className={style.link}>контакты</HashLink></li>
                    <li className={style.menuItem} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <div className="menu-text">окна</div>
                        {showDropdown && (
                            <div className={style.dropdown}>
                                <Link className={style.link} to="/restore/windowrestore">реставрация окон</Link>
                                <Link className={style.link} to="/windowmanufactoring">изготовление окон</Link>
                            </div>
                        )}
                    </li>
                    <li><Link to="/production" className={style.link}>производство</Link></li>
                </ul>
            </nav>
            <div className={style.contact}>
                <a href="tel:+7 (495) 792-44-04"><img src={phone_icon} alt="иконка телефона" /></a>
                <button onClick={scrollToElement}>Подать заявку</button>
            </div>
        </header>
    );
};

export default Header;
