import React from 'react';
import styles from './footer.module.css'
import geo from './geo.png'
import mail from './mail.png'
import tel from './tel.png'
import tg from './tg.png'
import vk from './vk.png'
import {  Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
    return (
        <div className={styles.footer}>
            <div className={styles.menu}>
                <Link to="/" >о компании</Link>
                <Link to="/services" >услуги</Link>
                <Link to="/projects" >проекты</Link>
                <HashLink to="/#contact" smooth >контакты</HashLink>
                <Link to="/restore" >реставрация</Link>
                <Link to="/production" >производство</Link>
            </div>
            <div className={styles.contactInfo}>
                <div className={styles.contactItem}>
                    <img className={styles.tegIcon} src={tel} alt="Phone"/>
                    <p>+7 (495) 792-44-04</p>
                </div>
                <div className={styles.contactItem}>
                    <img className={styles.tegIcon} src={mail} alt="Mail"/>
                    <p>triostroying@yandex.ru</p>
                </div>
                <div className={styles.contactItem}>
                    <img className={styles.tegIcon} src={geo} alt="Address"/>
                    <p>115093 г. Москва, вн.тер.г. муниципальный округ Замоскворечье
                        ул. Большая Серпуховская, д. 14/13, стр. 1, офис 16</p>
                </div>
            </div>

        </div>
    );
}

export default Footer;