import React, { useEffect, useState}  from 'react';
import style from './facts.module.css';
interface Fact {
    number: string;
    text: string;
}

interface Props {
    title: string;
    facts: Fact[];
}
export const factsData: Fact[] = [
    { number: "30+", text: 'объектов возведено' },
    { number: "c 2013", text: 'на рынке' },
    { number: "350+", text: 'квалифицированных\nсотрудников' },
];
const Facts: React.FC<Props> = ({ title, facts }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);

    const handleScroll = () => {
        setIsVisible(true);
    };
    const handleScroll2 = () => {
        setIsVisible2(true);
    };
    return (
            <div className={style.factsContainer} id="about">
                <h2 className={style.title}>{title}</h2>
                <p className={`${style.fadeIn2} ${isVisible2 && style.show} ${style.info}`} onScroll={handleScroll2}>
                    "ТриоСтройИнжиниринг" — это высококвалифицированная команда из более чем 350 специалистов, работающих на строительном рынке с 2013 года. Мы предлагаем комплексные решения в области управления строительными проектами и широкий спектр строительно-монтажных работ. Наши услуги охватывают все этапы строительства, от проектирования до ввода объектов в эксплуатацию.<br/> <br/>
                    Наша география включает Центральный и Южный регионы России, такие как Москва и Московская область, Перми, Сочи, Казань и Красноярский край.
                    <br></br>
                    <br></br>
                    Направления нашей компании:
                    <br></br>
                    <br></br>
                    -   Комплексное управление строительством<br/><br/>
                    -   Обеспечение высокого качества строительства собственными силами<br/><br/>
                    -   Технический надзор за выполнением работ<br/><br/>
                    -   Разработка технического задания и проектирование строительных мероприятий<br/><br/>
                    -   Подбор подрядчиков и специалистов<br/><br/>
                    -   Получение всех необходимых разрешений и согласований<br/><br/>
                    -   Реставрация и изготовление окон<br/><br/>
                    -   Подбор подрядчиков и специалистов<br/><br/>
                    <b className={style.endText}>МЫ ГОРДЫ СВОЕЙ ИСТОРИЕЙ, СВОИМ ПРОИЗВОДСТВОМ И СВОИМИ ИЗДЕЛИЯМИ!</b>
                </p>
            </div>

    );
}

export default Facts;